import { computed, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { SURVEYS, ACCOUNT, HELP, GAMES } from '@/constants/locales';
import type { DesktopNavItem } from '@/types';
import {
	PiHome,
	PiHomeFill,
	PiUserCircle,
	PiUserCircleFill,
	PiQuestionCircle,
	PiQuestionCircleFill,
	PiGameController,
	PiGameControllerFilled,
} from '@primeinsightsgroupllc/prime-icons';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { REWARDS_MODAL } from '@/constants/modals';
import { USER_ROUTE_NAME } from '@/constants/routes';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';

export const useNavDesktopItems = (isMobile = false) => {
	const { t } = useI18n();
	const route = useRoute();
	const router = useRouter();
	const { isTablet, isMobileApp } = useMobileApp();
	const { isOfferwallsAvailable } = storeToRefs(useUserStore());
	const { isAdjoeEnabled } = storeToRefs(useAppStore());

	const setActive = (item: DesktopNavItem) => {
		return Boolean(activeItem.value === item.id);
	};

	const setMobileIcon = (item: DesktopNavItem) => {
		if (activeItem.value === item.id) return item.iconActive;
		return item.icon;
	};

	const navigationClick = (item: DesktopNavItem) => {
		activeItem.value = item.id;
		router.push({ name: item.to });
	};

	const activeItem = ref<string>('');
	const items = computed<Array<DesktopNavItem>>(() => [
		{
			icon: PiHome,
			iconActive: PiHomeFill,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			active: false,
			disabled: false,
			id: 'surveys',
		},
		{
			icon: PiGameController,
			iconActive: PiGameControllerFilled,
			label: t(GAMES),
			to: USER_ROUTE_NAME.GAMES,
			active: false,
			disabled:
				!(isMobileApp && !isTablet()) ||
				!isOfferwallsAvailable.value ||
				!isAdjoeEnabled.value,
			test: 'games',
		},
		{
			icon: PiUserCircle,
			iconActive: PiUserCircleFill,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			active: false,
			disabled: false,
			id: 'profile',
		},
		{
			icon: PiQuestionCircle,
			iconActive: PiQuestionCircleFill,
			label: t(HELP),
			to: USER_ROUTE_NAME.SUPPORT,
			active: false,
			disabled: false,
			id: 'support',
		},
	]);
	const processItems = computed<DesktopNavItem[]>(() => {
		return items.value
			.map((item: DesktopNavItem) => ({
				...item,
				active: setActive(item),
				icon: isMobile ? setMobileIcon(item) : item.icon,
			}))
			.filter((item: DesktopNavItem) => !item.disabled);
	});

	onMounted(async () => {
		await router.isReady();
		activeItem.value =
			items.value.find((subItem: DesktopNavItem) => subItem.id === route.name)
				?.id || '';
	});

	return {
		items: processItems,
		navigationClick,
	};
};

export const useMainHeaderActions = () => {
	const { createNewModal } = useModalStorage();

	const openRewardsModal = async () => {
		await createNewModal(REWARDS_MODAL, { position: 'bottom' });
	};

	return {
		openRewardsModal,
	};
};
